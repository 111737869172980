.notFound {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: var(--app-height, 100vh);
  padding: 1rem;
  color: var(--color-white);
  background: url(../../assets/img/bg-dispatch.png) center no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 100rem rgba(40, 40, 40, 0.3);
}

.header {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.title,
.subtitle {
  margin-bottom: 0.75rem;
  text-align: center;
  text-transform: uppercase;
}

.title {
  margin-top: 2rem;
  font-size: 1.5rem;
  font-weight: 800;
}

.subtitle {
  font-size: 1rem;
  font-weight: 700;
}

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.logo {
  margin-top: calc(50vh - 32rem);
}

.footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.link {
  color: var(--color-white);
}

.linkMini {
  font-size: 0.75rem;
  line-height: 2.5;
}