/* Theming */

.themeGrandFrais {
  --container-bg: var(--color-gf-100);
  --container-border-color: var(--color-white);
  --link-color: var(--color-black);
  --link-bg: var(--color-white);
  --link-font: var(--font-gf-secondary);
}

.themeFresh {
  --container-bg: var(--color-fresh-100);
  --container-border-color: var(--color-fresh-200);
  --link-color: var(--color-white);
  --link-bg: var(--color-fresh-200);
  --link-font: var(--font-fresh-secondary);
}

/* Global */

.footer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  background-color: var(--container-bg);
  border-top: 1px solid var(--container-border-color);
}

.links {
  margin-right: 6rem;
}

.link {
  display: block;
  color: var(--link-color);
  font-family: var(--link-font);
}

.link:not(:last-child) {
  margin-bottom: 0.5rem;
}

.linkMini {
  font-size: 0.875rem;
  line-height: 2;
}

.button {
  color: var(--link-color);
  background-color: var(--link-bg);
  border: 0;
}

.linkRounded {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
}

.picto {
  margin-bottom: 0.125rem;
}