/* Global styles */

html,
body,
#root {
  min-height: 100vh;
  min-height: var(--app-height, 100vh);
}

body {
  color: var(--color-black);
  font-size: 16px;
  font-family: var(--font-base);
  font-weight: 400;
}

a {
  color: var(--color-primary-100);
  text-decoration: none;
}

.wrapper {
  position: relative;
  max-width: 40rem;
  margin: auto;
  padding-left: var(--padding-xl);
  padding-right: var(--padding-xl);
}

.loader {
  --loader-width: 3.125rem;
  position: relative;
  top: calc(50vh - (var(--loader-width) / 2));
  left: calc(50% - (var(--loader-width) / 2));
  border: 5px solid #f3f3f3;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: var(--loader-width);
  height: var(--loader-width);
}

/* Helpers */

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.text-fresh-100 {
  color: var(--color-fresh-100);
}

.text-fresh-300 {
  color: var(--color-fresh-300);
}

.text-gf-100 {
  color: var(--color-gf-100);
}

.text-gf-300 {
  color: var(--color-gf-300);
}

.animate {
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  animation-iteration-count: 1;
}

/* Customize plugin */

.legended .slick-dots {
  bottom: -4.75rem;
}
 
.slick-dots {
  bottom: 1rem;
}

.slick-dots li {
  margin: 0 0.5rem;
}

.slick-dots li,
.slick-dots li button {
  width: 0.5rem;
  height: 0.5rem;
}

.slick-dots li.slick-active,
.slick-dots li.slick-active button {
  width: 0.75rem;
  height: 0.75rem;
}

.slick-dots li button {
  padding: 0;
  border: 1px solid var(--color-white);
  border-radius: 50%;
}

.slick-dots li.slick-active button {
  background: var(--color-white);
}

.slick-dots li button::before {
  display: none;
}

.slick-list {
  padding-bottom: 2.5rem;
}

/* Animations */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
