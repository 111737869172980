/* Theming */

.themeGrandFrais {
  --container-bg: var(--color-gf-100);
  --button-font: var(--font-gf-primary);
  --button-font-size: 1rem;
  --share-color: var(--color-black);
  --share-bg: var(--color-white);
  --scan-color: var(--color-white);
  --scan-bg: var(--color-gf-300);
}

.themeFresh {
  --container-bg: var(--color-white);
  --button-font: var(--font-fresh-primary);
  --button-font-size: 0.875rem;
  --share-color: var(--color-white);
  --share-bg: var(--color-fresh-300);
  --scan-color: var(--color-white);
  --scan-bg: var(--color-fresh-100);
}

/* Global */

.header {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 64rem;
  min-height: 5rem;
  background-color: var(--container-bg);
  transform: translateY(-8.5rem);
  transition: transform 0.35s ease;
  z-index: 4;
}

.visible {
  transform: translateY(0);
}

.logo {
  position: absolute;
  top: 0.375rem;
  left: 1.25rem;
}

.actions {
  display: flex;
}

.share,
.scan {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0;
  font-family: var(--button-font);
  font-size: var(--button-font-size);
  line-height: 1;
  text-transform: uppercase;
  border: 0;
  box-shadow: none;
}

.share {
  flex-direction: column;
  width: 5rem;
  color: var(--share-color);
  background-color: var(--share-bg);
}

.scan {
  width: 8.75rem;
  color: var(--scan-color);
  background-color: var(--scan-bg);
}

.sharePicto {
  margin-bottom: 0.625rem;
}

.scanPicto {
  margin-left: 1rem;
}

.scanLabel {
  text-align: right;
}

.modalScan {
  position: fixed;
  inset: 0;
  background-color: var(--color-black);
  z-index: 5;
}

.modalHeader {
  display: flex;
  align-items: center;
  padding: 2rem;
  color: var(--color-white);
}

.modalPicto {
  flex-shrink: 0;
  margin-right: 1rem;
}

.modalInfos {
  max-width: 14.375rem;
}

.modalTitle {
  font-size: 1.25rem;
  font-weight: 700;
}

.modalSubtitle {
  font-size: 0.75rem;
}

.modalClose {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  height: 1.25rem;
  background-color: transparent;
  padding: 0;
  border: 0;
}