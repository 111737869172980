/* Theming */

.themeGrandFrais {
  --container-bg: #e0ddd9 ;
  --container-bg-url: url(../../../assets/img/bg-bois.jpg);
  --container-bg-url-2: url(../../../assets/img/couverts-black.svg);
  --title-color: var(--color-white);
  --title-bg: var(--color-black);
  --title-font: var(--font-gf-primary);
  --title-font-size: 2.125rem;
  --title-line-height: 1;
  --text-color: var(--color-black);
  --text-font: var(--font-gf-secondary);
  --picto-bg: var(--color-black);
  --label-color: var(--color-black);
  --label-font-size: 1.5rem;
  --label-line-height: 1;
}

.themeFresh {
  --container-bg: #e0ddd9 ;
  --container-bg-url: url(../../../assets/img/bg-bois.jpg);
  --container-bg-url-2: url(../../../assets/img/couverts-green.svg);
  --title-color: var(--color-white);
  --title-bg: var(--color-fresh-300);
  --title-font: var(--font-fresh-primary);
  --title-font-size: 1.75rem;
  --title-line-height: 1.2;
  --text-color: var(--color-fresh-300);
  --text-font: var(--font-fresh-secondary);
  --picto-bg: var(--color-fresh-300);
  --label-color: var(--color-fresh-300);
  --label-font-size: 1rem;
  --label-line-height: 1.2;
}

/* Global */

.advice {
  position: relative;
  padding: 3rem 0;
  text-align: center;
}

.advice::before {
  content: '';
  display: block;
  position: absolute;
  top: -1.75rem;
  left: 50%;
  background-image: var(--container-bg-url-2), var(--container-bg-url);
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  background-size: auto, cover;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.themeGrandFrais.advice {
  background: var(--container-bg-url) center no-repeat;
  background-size: cover;
}

.themeFresh.advice::after {
  content: '';
  display: block;
  position: absolute;
  top: -1rem;
  left: 0;
  width: 100%;
  height: calc(100% + 2rem);
  background: var(--container-bg-url) center no-repeat;
  background-size: cover;
  clip-path: polygon(0% 5%, 100% 0, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
  z-index: -2;
}

.title,
.listLabel {
  font-family: var(--title-font);
  text-transform: uppercase;
  text-align: left;
}

.title {
  display: inline;
  padding: 0 0.5rem;
  color: var(--title-color);
  background-color: var(--title-bg);
  font-size: var(--title-font-size);
  line-height: var(--title-line-height);
  box-decoration-break: clone;
}

.subtitle {
  margin-top: 2rem;
  color: var(--text-color);
  font-family: var(--text-font);
  font-size: 1rem;
}

.text {
  margin-top: 1.25rem;
  color: var(--text-color);
  font-family: var(--text-font);
  font-size: 1rem;
  line-height: 1.5;
}

.list {
  width: fit-content;
  margin: 1.25rem auto 0;
  padding: 0;
  list-style: none;
}

.listItem {
  display: flex;
  align-items: center;
}

.listItem:not(:last-child) {
  margin-bottom: 0.875rem;
}

.listPicto {
  display: flex;
  background-color: var(--picto-bg);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
}

.picto {
  margin: auto;
}

.listLabel {
  margin-left: 1rem;
  font-size: var(--label-font-size);
  line-height: var(--label-line-height);
}
