.etc {
  margin-right: 0.25rem;
}

.button {
  margin: 0;
  padding: 0;
  color: var(--color-black);
  background-color: var(--color-white);
  font-family: inherit;
  font-size: 1rem;
  border: 0;
}