/* Fresh fonts */

@font-face {
	font-family: 'Mixed Breed';
	src: url('../fonts/mixed-breed-bold.woff2') format('woff2');
  font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Boton';
	src: url('../fonts/boton.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Boton';
	src: url('../fonts/boton-italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Boton';
	src: url('../fonts/boton-medium.otf') format('opentype');
	font-weight: 500;
	font-style: normal;
}

/* Grand Frais fonts */

@font-face {
	font-family: 'DIN';
	src: url('../fonts/din.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'DIN';
	src: url('../fonts/din-bold.otf') format('opentype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'NAL Hand';
	src: url('../fonts/nal-hand.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

/* Global base font */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap');