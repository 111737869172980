/* Theming */

.themeGrandFrais {
  --content-color: transparent;
  --button-bg: var(--color-white);
  --video-color: var(--color-black);
  --video-bg: var(--color-white);
  --video-font: var(--font-gf-primary);
  --video-font-size: 1.25rem;
  --title-color: var(--color-black);
  --title-bg-first-line: var(--color-gf-200);
  --title-font: var(--font-gf-primary);
  --title-font-size: 3.5rem;
  --banner-bg-url: url(../../../assets/img/banner-yellow.svg);
  --banner-font: var(--font-gf-secondary);
  --label-color: var(--color-gf-100);
  --label-font: var(--font-gf-primary);
  --label-font-size: 1.5rem;
  --label-line-height: 1;
  --body-color: var(--color-black);
  --body-font: var(--font-gf-secondary);
}

.themeFresh {
  --content-color: var(--color-white);
  --button-bg: var(--color-fresh-300);
  --video-color: var(--color-fresh-300);
  --video-bg: var(--color-fresh-100);
  --video-font: var(--font-fresh-primary);
  --video-font-size: 1rem;
  --title-color: var(--color-fresh-100);
  --title-bg-first-line: transparent;
  --title-font: var(--font-fresh-primary);
  --title-font-size: 2.5rem;
  --banner-bg-url: url(../../../assets/img/banner-brown.svg);
  --banner-font: var(--font-fresh-secondary);
  --label-color: var(--color-fresh-100);
  --label-font: var(--font-fresh-primary);
  --label-font-size: 1rem;
  --label-line-height: 1.2;
  --body-color: var(--color-fresh-300);
  --body-font: var(--font-fresh-secondary);
}

/* Global */

.product {
  padding: 0;
}

.themeFresh.product {
  padding-bottom: 1rem;
}

.illustration {
  position: relative;
}

.themeFresh .illustration {
  height: 24.875rem;
  clip-path: polygon(0 0, 0 90%, 100% 100%, 100% 0);
}

.slides {
  width: 100%;
  height: 25.3125rem;
}

@media (min-width: 48rem) {
  .themeFresh .illustration {
    height: 32.875rem;
  }

  .slides {
    height: 33.3125rem;
  }
}

.picture {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.macaron {
  position: absolute;
  bottom: 1.25rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.logo {
  position: absolute;
  bottom: 3rem;
  right: calc(50% - 70px);
  z-index: 1;
}

.content {
  position: relative;
  margin: -3.125rem auto 0;
  padding: 1.125rem 0.5rem 0;
  background-color: var(--content-color);
  text-align: center;
  z-index: 2;
}

.title {
  position: relative;
  margin-bottom: 0.5rem;
  color: var(--title-color);
  font-family: var(--title-font);
  font-size: var(--title-font-size);
  line-height: 1;
  white-space: pre-line;
}

.themeGrandFrais .title {
  text-transform: uppercase;
}

.themeGrandFrais .title-background {
  background-color: var(--title-bg-first-line);
  padding: 0 1rem;
}

.banner {
  display: block;
  margin: auto;
  width: 14.125rem;
  font-family: var(--banner-font);
  font-size: 0.875rem;
  line-height: 1.5625rem;
}

.pictosTitles {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  align-items: flex-end;
}

.pictos {
  display: flex;
  justify-content: center;
  width: 100%;
}

.picto {
  text-align: center;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 30%;
  margin: 0 0.5rem;
}

.pictoContainer {
  margin: 0.875rem 0;
  background-color: #c2cd23;
  border-radius: 8px 8px 0 0;
  padding: 0.75rem;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.pictoImg {
  margin: 0 0.25rem;
}

.pictoImgIntensity {
  margin-right: -0.25rem;
}

.intensity {
  height: 3.6875rem;
  margin-top: 0.625rem;
}

.pictoTitle,
.pictoLabel {
  font-family: var(--label-font);
  font-size: var(--label-font-size);
  line-height: var(--label-line-height);
  text-transform: uppercase;
}

.pictoDescription {
  font-family: var(--font-gf-secondary);
  font-size: 0.9rem;
  line-height: 80%;
  margin-left: 0.25rem;
}

.pictoTitle {
  font-family: var(--label-font);
  color: var(--label-color);
  font-size: var(--label-font-size);
}

.refinementContent {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* .pictoLabel::first-line {
  color: var(--label-color);
} */

.body {
  margin: 1.5625rem 0 3.125rem;
  color: var(--body-color);
  font-family: var(--body-font);
  font-size: 1.25rem;
  line-height: 1.2;
  text-align: center;
}

.divider {
  margin-top: 3.5rem;
  width: 1.875rem;
  height: 1px;
  background-color: var(--color-black);
}

.video {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 0;
}

.buttonVideo {
  width: 3.625rem;
  height: 3.625rem;
  background-color: var(--button-bg);
  border-radius: 50%;
}

.videoLabel {
  display: flex;
  justify-content: space-between;
  color: var(--video-color);
  font-family: var(--video-font);
  font-size: var(--video-font-size);
  line-height: 1;
  text-transform: uppercase;
}

.themeFresh .video {
  top: 25.3125rem;
  width: 100%;
  max-width: 15.625rem;
  background-color: var(--video-bg);
}

@media (min-width: 48rem) {
  .themeFresh .video {
    top: 33.3125rem;
  }
}

.themeFresh .buttonVideo {
  position: absolute;
  top: -1.0625rem;
  left: 50%;
  transform: translateX(-50%);
}

.themeFresh .videoLabel {
  padding: 0.3125rem 0.375rem 0.1875rem;
}

.themeFresh .videoLabelBlock {
  width: 5.375rem;
  text-align: center;
}

.themeGrandFrais .video {
  top: 21rem;
}

@media (min-width: 48rem) {
  .themeGrandFrais .video {
    top: 29rem;
  }
}

.themeGrandFrais .buttonVideo {
  margin: 0 auto 0.5rem;
}

.themeGrandFrais .videoLabel {
  padding: 0.3125rem 0.5rem 0.1875rem;
  background-color: var(--video-bg);
}

.modalWrapper {
  position: fixed;
  inset: 0;
  display: flex;
  padding: 2rem;
  background-color: rgb(255 255 255 / 80%);
  z-index: 5;
}

.modalContent {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 32rem;
}

.modalClose {
  position: absolute;
  top: -1.125rem;
  right: -1.125rem;
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--color-black);
  border-radius: 50%;
  z-index: 1;
}

.videoWrapper {
  position: relative;
  padding-top: 56.25%;
  background-color: var(--color-black);
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
}
