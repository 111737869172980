/* Theming */

.themeGrandFrais {
  --container-bg: var(--color-black);
  --container-color: var(--color-gf-200);
  --title-color: var(--color-black);
  --title-bg: var(--color-white);
  --title-font: var(--font-gf-primary);
  --title-font-size: 2.125rem;
  --title-line-height: 1;
  --author-color: var(--color-white);
  --author-font: var(--font-gf-primary);
  --author-font-size: 1.25rem;
  --quote-color: var(--color-white);
  --quote-font: var(--font-gf-secondary);
}

.themeFresh {
  --container-bg: var(--color-fresh-300);
  --container-color: var(--color-white);
  --title-color: var(--color-fresh-100);
  --title-bg: var(--color-white);
  --title-font: var(--font-fresh-primary);
  --title-font-size: 1.75rem;
  --title-line-height: 1.2;
  --author-color: var(--color-white);
  --author-font: var(--font-fresh-primary);
  --author-font-size: 1rem;
  --quote-color: var(--color-white);
  --quote-font: var(--font-fresh-secondary);
}

/* Global */

.opinion {
  position: relative;
  padding: 3rem 0;
  background-color: var(--container-bg);
}

.opinion::before {
  content: '“';
  display: block;
  position: absolute;
  top: -1.75rem;
  left: 50%;
  padding-top: 0.5rem;
  color: var(--container-color);
  background-color: var(--container-bg);
  width: 3.5rem;
  height: 3.5rem;
  font-family: var(--font-fresh-primary);
  font-size: 2.5rem;
  text-align: center;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.themeFresh.opinion {
  padding: 2rem 0 4rem;
}

.themeFresh.opinion::before {
  top: -2.5rem;
}

.themeFresh.opinion::after {
  content: '';
  display: block;
  position: absolute;
  top: -1.875rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background-color: var(--container-bg);
  clip-path: polygon(0% 0%, 100% 90%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
}

.title,
.author {
  font-family: var(--title-font);
  text-transform: uppercase;
}

.title {
  max-width: 12.5rem;
}

.titleInner {
  display: inline;
  padding: 0 0.5rem;
  color: var(--title-color);
  background-color: var(--title-bg);
  font-size: var(--title-font-size);
  line-height: var(--title-line-height);
  box-decoration-break: clone;
}

.author {
  margin: 2rem 0;
  color: var(--author-color);
  font-size: var(--author-font-size);
  line-height: 1.2;
}

.profile {
  position: absolute;
  top: -1rem;
  right: -2rem;
  width: 10.375rem;
  height: 10.375rem;
  border-radius: 50%;
  overflow: hidden;
}

.profilePic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.quote {
  color: var(--quote-color);
  font-family: var(--quote-font);
  font-size: 1rem;
  font-style: italic;
  line-height: 1.5;
}

.center {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
}
