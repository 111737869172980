/* Theming */

.themeGrandFrais {
  --container-bg: var(--color-white);
  --title-color: var(--color-white);
  --title-bg: var(--color-gf-100);
  --title-font: var(--font-gf-primary);
  --title-font-size: 2.125rem;
  --title-line-height: 1;
  --subtitle-color: var(--color-black);
  --subtitle-font: var(--font-gf-primary);
  --subtitle-font-size: 1.625rem;
  --subtitle-line-height: 1;
  --text-color: var(--color-black);
  --text-font: var(--font-gf-secondary);
}

.themeFresh {
  --container-bg: var(--color-white);
  --title-color: var(--color-white);
  --title-bg: var(--color-fresh-100);
  --title-font: var(--font-fresh-primary);
  --title-font-size: 1.75rem;
  --title-line-height: 1.5;
  --subtitle-color: var(--color-fresh-300);
  --subtitle-font: var(--font-fresh-primary);
  --subtitle-font-size: 1.25rem;
  --subtitle-line-height: 1.1;
  --text-color: var(--color-fresh-300);
  --text-font: var(--font-fresh-secondary);
}

/* Global */

.didYouKnow {
  position: relative;
  padding: 3rem 0;
  text-align: center;
}

.themeFresh.didYouKnow {
  padding: 2rem 0 4rem;
}

.themeFresh.didYouKnow::after {
  content: '';
  display: block;
  position: absolute;
  top: -1.875rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background-color: var(--container-bg);
  clip-path: polygon(0% 0%, 100% 90%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
}

.title,
.subtitle {
  font-family: var(--title-font);
  text-transform: uppercase;
}

.title {
  display: inline-block;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  color: var(--title-color);
  background-color: var(--title-bg);
  font-size: var(--title-font-size);
  line-height: var(--title-line-height);
}

.subtitle {
  max-width: calc(100% - 7.5rem);
  margin: 0 auto 1rem;
  color: var(--subtitle-color);
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
}

.picto {
  position: absolute;
  top: -0.5rem;
  right: -1rem;
}

.text {
  color: var(--text-color);
  font-family: var(--text-font);
  font-size: 1rem;
  line-height: 1.5;
}