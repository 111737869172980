/* Theming */

.themeGrandFrais {
  --container-bg: var(--color-gf-100);
  --title-color: var(--color-white);
  --title-font: var(--font-gf-primary);
  --title-font-size: 2.125rem;
  --link-color: var(--color-black);
  --link-bg: var(--color-white);
  --link-font: var(--font-gf-secondary);
}

.themeFresh {
  --container-bg: var(--color-fresh-100);
  --title-color: var(--color-white);
  --title-font: var(--font-fresh-primary);
  --title-font-size: 1.75rem;
  --link-color: var(--color-fresh-100);
  --link-bg: var(--color-white);
  --link-font: var(--font-fresh-primary);
}

/* Global */

.socials {
  position: relative;
  padding: 2rem 0;
  background-color: var(--container-bg);
  text-align: center;
}

.themeFresh.socials::after {
  content: '';
  display: block;
  position: absolute;
  top: -1.875rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background-color: var(--container-bg);
  clip-path: polygon(0% 90%, 100% 0, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
}

.title {
  margin-bottom: 1.5rem;
  color: var(--title-color);
  font-family: var(--title-font);
  font-size: var(--title-font-size);
  line-height: 1.2;
  text-transform: uppercase;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  flex-shrink: 0;
  width: 100%;
  max-width: fit-content;
  padding: 1.125rem;
  color: var(--link-color);
  background-color: var(--link-bg);
  font-family: var(--link-font);
  text-align: center;
  border-radius: 3.125rem;
}

.link:not(:last-child) {
  margin-right: 1.5rem;
}

.linkRounded {
  display: flex;
  max-width: 3.75rem;
  height: 3.75rem;
  border-radius: 50%;
}

.picto {
  margin: auto;
}