.legals {
  padding: 2rem;
}

.title {
  margin: 3rem 0;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
  text-align: center;
}

.subtitle {
  margin: 2rem 0 1rem;
  font-weight: 700;
  line-height: 1.5;
}

.paragraph {
  margin-bottom: 1rem;
  line-height: 1.5;
}

.button {
  margin: 0;
  padding: 0;
  color: var(--color-fresh-100);
  background-color: var(--color-white);
  font-family: var(--font-base);
  font-size: 0.875rem;
  border: 0;
}

.link {
  color: var(--color-fresh-100);
}