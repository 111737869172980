/* Theming */

.themeGrandFrais {
  --container-bg: var(--color-white);
}

.themeFresh {
  --container-bg: var(--color-white);
}

/* Global */

.regulation {
  position: relative;
  padding: 1.5rem 0;
  background-color: var(--container-bg);
  text-align: center;
}

.themeFresh.regulation {
  padding: 0.5rem 0 2.5rem;
}

.themeFresh.regulation::after {
  content: '';
  display: block;
  position: absolute;
  top: -1.875rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background-color: var(--container-bg);
  clip-path: polygon(0% 90%, 100% 0, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
}