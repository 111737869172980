/* Theming */

.themeGrandFrais {
  --container-bg: var(--color-gf-200);
  --container-bg-url: url(../../../assets/img/pin-black.svg);
  --title-color: var(--color-white);
  --title-bg: var(--color-black);
  --title-font: var(--font-gf-primary);
  --quote-font: var(--font-gf-secondary);
  --title-font-size: 2.125rem;
  --subtitle-font-size: 1.625rem;
}

.themeFresh {
  --container-bg: var(--color-fresh-100);
  --container-bg-url: url(../../../assets/img/pin-white.svg);
  --title-color: var(--color-white);
  --title-bg: var(--color-fresh-300);
  --title-font: var(--font-fresh-primary);
  --quote-font: var(--font-fresh-secondary);
  --title-font-size: 1.75rem;
  --subtitle-font-size: 1.25rem;
}

/* Global */

.location {
  position: relative;
  padding: 3rem 0;
  background-color: var(--container-bg);
}

.location::before {
  content: '';
  display: block;
  position: absolute;
  top: -1.75rem;
  left: 50%;
  background: var(--container-bg) var(--container-bg-url) center no-repeat;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.themeFresh.location {
  padding: 2rem 0 4rem;
}

.themeFresh.location::before {
  top: -2.5rem;
}

.themeFresh.location::after {
  content: '';
  display: block;
  position: absolute;
  top: -1.875rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background-color: var(--container-bg);
  clip-path: polygon(0% 0%, 100% 90%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
}

.title {
  font-family: var(--title-font);
  text-transform: uppercase;
}

.title {
  max-width: 12.5rem;
}

.titleInner {
  display: inline;
  padding: 0 0.5rem;
  color: var(--title-color);
  background-color: var(--title-bg);
  font-size: var(--title-font-size);
  line-height: var(--title-line-height);
  box-decoration-break: clone;
  max-width: 100%;
}

.profile {
  position: absolute;
  top: -1rem;
  right: -2rem;
  width: 10.375rem;
  height: 10.375rem;
  border-radius: 50%;
  overflow: hidden;
  z-index: 1;
}

.profilePic {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.quote {
  font-family: var(--quote-font);
  font-size: 1rem;
  line-height: 1.5;
  margin: 2rem 0;
  white-space: normal;
}

.subtitle {
  font-size: var(--subtitle-font-size);
  line-height: 1.1;
  font-family: var(--title-font);
  text-transform: uppercase;
  margin-top: 1rem  ;
}

.mapContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.map {
  margin: auto;
}

.center {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
}
