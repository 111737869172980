:root {
  /* Colors */
  --color-fresh-100: #a0c530;
  --color-fresh-200: #85a81d;
  --color-fresh-300: #0e3527;
  --color-gf-100: #b5cd34;
  --color-gf-200: #ffdd00;
  --color-gf-300: #ee3224;
  --color-white: #fff;
  --color-black: #2b2b2b;
  --color-grey: #e9e3cf;

  /* Font families */
  --font-base: 'Montserrat', sans-serif;
  --font-fresh-primary: 'Mixed Breed', sans-serif;
  --font-fresh-secondary: 'Boton', serif;
  --font-gf-primary: 'NAL Hand', sans-serif;
  --font-gf-secondary: 'DIN', sans-serif;

  /* Font Sizes */
  --fs-s: 0.5rem;
  --fs-m: 1rem;
  --fs-l: 1.25rem;
  --fs-xl: 1.75rem;

  /* Padding */
  --padding-xs: 0.25rem;
  --padding-s: 0.5rem;
  --padding-m: 1rem;
  --padding-l: 1.5rem;
  --padding-xl: 2rem;

  /* Margin */
  --margin-xs: 0.25rem;
  --margin-s: 0.5rem;
  --margin-m: 1rem;
  --margin-l: 1.5rem;
  --margin-xl: 2rem;
}
