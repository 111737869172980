/* Theming */

.themeGrandFrais {
  --container-bg-url: url(../../../assets/img/bg-recettes-gf.jpg);
  --link-color: var(--color-black);
  --link-bg: var(--color-gf-100);
  --link-font: var(--font-gf-primary);
  --link-font-size: 1.25rem;
}

.themeFresh {
  --container-bg-url: url(../../../assets/img/bg-recettes-fresh.jpg);
  --link-color: var(--color-white);
  --link-bg: var(--color-fresh-300);
  --link-font: var(--font-fresh-primary);
  --link-font-size: 1rem;
}

/* Global */

.receipts {
  position: relative;
  display: flex;
  min-height: 23.75rem;
}

.themeGrandFrais.receipts {
  background: var(--container-bg-url) center no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 100rem rgba(40, 40, 40, 0.2);
}

.themeFresh.receipts::after {
  content: '';
  display: block;
  position: absolute;
  top: -1rem;
  left: 0;
  width: 100%;
  height: calc(100% + 2rem);
  background: var(--container-bg-url) center no-repeat;
  background-size: cover;
  box-shadow: inset 0 0 0 100rem rgba(40, 40, 40, 0.2);
  clip-path: polygon(0% 0%, 100% 7%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
  z-index: -2;
}

.link {
  width: 100%;
  max-width: 15.625rem;
  margin: auto;
  padding: 1.125rem;
  color: var(--link-color);
  background-color: var(--link-bg);
  font-family: var(--link-font);
  font-size: var(--link-font-size);
  text-align: center;
  text-transform: uppercase;
  border-radius: 3.125rem;
}