/* Theming */

.themeGrandFrais {
  --container-bg: var(--color-white);
  --title-color: var(--color-white);
  --title-bg: var(--color-gf-100);
  --title-font: var(--font-gf-primary);
  --title-font-size: 2.125rem;
  --title-line-height: 1;
  --subtitle-color: var(--color-black);
  --subtitle-font: var(--font-gf-primary);
  --subtitle-font-size: 1.625rem;
  --subtitle-line-height: 1;
  --text-color: var(--color-black);
  --text-font: var(--font-gf-secondary);
  --button-bg: var(--color-gf-100);
  --illustration-bg: var(--color-black);
  --legend-title-color: var(--color-white);
  --legend-title-bg: var(--color-black);
  --legend-title-font: var(--font-gf-primary);
  --legend-title-font-size: 1.25rem;
  --legend-title-line-height: 1;
  --legend-text-font: var(--font-gf-secondary);
  --legend-text-font-size: 1rem;
  --legend-text-line-height: 1.5;
}

.themeFresh {
  --container-bg: var(--color-white);
  --title-color: var(--color-white);
  --title-bg: var(--color-fresh-100);
  --title-font: var(--font-fresh-primary);
  --title-font-size: 1.75rem;
  --title-line-height: 1.5;
  --subtitle-color: var(--color-fresh-300);
  --subtitle-font: var(--font-fresh-primary);
  --subtitle-font-size: 1.25rem;
  --subtitle-line-height: 1.1;
  --text-color: var(--color-fresh-300);
  --text-font: var(--font-fresh-secondary);
  --button-bg: var(--color-fresh-100);
  --illustration-bg: var(--color-fresh-300);
  --legend-title-color: var(--color-white);
  --legend-title-bg: var(--color-fresh-300);
  --legend-title-font: var(--font-fresh-primary);
  --legend-title-font-size: 1rem;
  --legend-title-line-height: 1;
  --legend-text-font: var(--font-fresh-secondary);
  --legend-text-font-size: 1rem;
  --legend-text-line-height: 1.5;
}

/* Global */

.generic {
  position: relative;
  padding: 3rem 0 0;
  text-align: center;
}

.themeFresh.generic {
  padding: 2rem 0 0;
}

.themeFresh.generic::after {
  content: '';
  display: block;
  position: absolute;
  top: -1.875rem;
  left: 0;
  width: 100%;
  height: 2rem;
  background-color: var(--container-bg);
  clip-path: polygon(0% 0%, 100% 90%, 100% 100%, 100% 100%, 0% 100%, 0% 100%);
}

.title,
.subtitle {
  font-family: var(--title-font);
  text-transform: uppercase;
}

.title {
  display: inline;
  padding: 0 0.5rem;
  color: var(--title-color);
  background-color: var(--title-bg);
  font-size: var(--title-font-size);
  line-height: var(--title-line-height);
  box-decoration-break: clone;
}

.subtitle {
  margin-top: 1rem;
  color: var(--subtitle-color);
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
}

.text {
  margin-top: 1rem;
  color: var(--text-color);
  font-family: var(--text-font);
  font-size: 1rem;
  line-height: 1.5;
}

.text button {
  color: var(--title-bg);
}

.illustration {
  margin-top: 2.25rem;
}

.illustrationWithLegend {
  background-color: var(--illustration-bg);
}
.sliderWithLegend {
  transform: translateY(-9rem);
}

.slides {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: -4px; /* Slider quickfix */
  padding: 1.5rem 1.5rem 0 1.5rem;
}

@media (min-width: 48rem) {
  .slides {
    height: 32.375rem;
    width: auto !important;
  }
}

.sliderWithLegend .slides {
  height: 18.125rem;
  padding: 0.75rem;
}

@media (min-width: 48rem) {
  .sliderWithLegend .slides {
    height: 32.125rem;
    padding: 1.5rem;
  }
}

.slidesImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.sliderWithLegend .slidesImg {
  border-radius: 0.625rem;
  overflow: hidden;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 0;
}

.buttonVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3.625rem;
  height: 3.625rem;
  background-color: var(--button-bg);
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.legendTitle,
.legendText {
  color: var(--legend-title-color);
}

.legendTitle {
  position: absolute;
  bottom: 0rem;
  left: 50%;
  padding: 0.5rem;
  background-color: var(--legend-title-bg);
  font-family: var(--legend-title-font);
  font-size: var(--legend-title-font-size);
  line-height: var(--legend-title-line-height);
  transform: translateX(-50%);
}

.themeGrandFrais .legendTitle {
  text-transform: uppercase;
}

.legendText {
  padding-top: 2.5rem;
  font-family: var(--legend-text-font);
  font-size: var(--legend-text-font-size);
  line-height: var(--legend-text-line-height);
}

.modalWrapper {
  position: fixed;
  inset: 0;
  display: flex;
  padding: 2rem;
  background-color: rgba(255,255,255,0.8);
  z-index: 5;
}

.modalContent {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 32rem;
}

.modalClose {
  position: absolute;
  top: -1.125rem;
  right: -1.125rem;
  width: 2.25rem;
  height: 2.25rem;
  background-color: var(--color-black);
  border-radius: 50%;
  z-index: 1;
}

.videoWrapper {
  position: relative;
  padding-top: 56.25%;
  background-color: var(--color-black);
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
}
